import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader, ModalFooter } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';

export default class Planeacion extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date().yyyymmdd('-');
        this.state = {
            mostrarDialogo:false,
            cargando:false,
            editando:false,
            ejercicio:0,
            fechaIni:'',
            fechaFin:'',
            fecha:fec,
            semana:0,
            chofer:0,
            camion:0,
            sucursal:0,
            titulo:'',
            ruta:'',
            mov:{},
            sucursales:[],
            semanas:[],
            movs:[],
            camiones:[],
            rutas:[],
            choferes:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        let f = new Date();
        let fec = f.yyyymmdd('-');
        let ejer = f.getFullYear();
        this.setState({cargando:true});
        let url = `${this.props.urlws}/viaje/camiones`;
        let resp = await fetch(url);
        const camiones = await resp.json();
        url = `${this.props.urlws}/viaje/rutas`;
        resp = await fetch(url);
        const rutas = await resp.json();
        url = `${this.props.urlws}/viaje/choferes`;
        resp = await fetch(url);
        const choferes = await resp.json();
        url = `${this.props.urlws}/home/sucursales`;
        resp = await fetch(url);
        const sucursales = await resp.json();
        url = `${this.props.urlws}/viaje/semanas/${ejer}?fecha=${fec}`;
        resp = await fetch(url);
        const semanas = await resp.json();
        this.state.sucursal = sucursales[0].I;
        this.setState({cargando:false, sucursales, camiones, rutas, choferes, semanas, ejercicio:ejer });
        this.cargarMovs();
    }
    cargarMovs = async () => {
        if(this.state.sucursal == 0){
            Swal.fire('Atencion','Espeficique la sucursal','error');
            return;
        }
        this.setState({cargando:true, mostrarDialogo:false});
        let url = `${this.props.urlws}/viaje/planeacion?ejercicio=${this.state.ejercicio}&semana=${this.state.semana}&sucursal=${this.state.sucursal}`;
        let resp = await fetch(url);
        const movs = await resp.json();
        url = `${this.props.urlws}/viaje/cargarSemana?ejer=${this.state.ejercicio}&semana=${this.state.semana}`;
        resp = await fetch(url);
        const sem = await resp.json();
        this.setState({cargando:false,  movs, fechaIni:sem.Inicio, fechaFin:sem.Fin });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    onChangeCarga = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor}, () => this.cargarMovs() );
    }
    handleClose = () =>{
        this.setState({mostrarDialogo:false});
    }
    nuevoMov = ()=>{
        if(this.state.sucursal == 0){
            Swal.fire('Atencion','Espeficique la sucursal','error');
            return;
        }
        if(this.state.semana == 0){
            Swal.fire('Atencion','Espeficique la semana','error');
            return;
        }
        this.setState({
            mov:{ Id: 0},
            mostrarDialogo:true,
            titulo:'Agregando',
            chofer:0,
            ruta:'',
            camion:0
        });
    }
    editar = (m)=>{
        this.setState({
            mov:m,
            mostrarDialogo:true,
            titulo:'Editando',
            chofer:m.Chofer,
            ruta:m.Ruta,
            camion:m.Camion
        });
    }
    eliminar = (m)=>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar este registro ( ruta : " + m.Ruta + " / Camion : " + m.NomCam + " / Chofer : " + m.NomCh + ") ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.eliminarB(m.Id);
            }
          })        
    }
    eliminarB = async(id) =>{
        const url = this.props.urlws +"/viaje/eliminarPlaneacion/" + id;
        this.setState({cargando:true});
        const resp = await fetch(url,{
            method: 'POST'
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Registro eliminado','success');
            this.cargarMovs();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    guardar = async() => {
        let {mov} = this.state;
        mov.Fecha = this.state.fecha;
        if(mov.Fecha < this.state.fechaIni || mov.Fecha > this.state.fechaFin){
            Swal.fire('Atencion','La fecha no es de semana la indicada','error');
            return;
        }
        mov.Sucursal = this.state.sucursal;
        mov.Semana = this.state.semana;
        mov.Ejercicio = this.state.ejercicio;
        mov.Chofer = this.state.chofer;
        mov.Camion = this.state.camion;
        mov.Ruta = this.state.ruta;
        const url = this.props.urlws +"/viaje/wsguardarPlaneacion";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.cargarMovs();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    render () {
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-3">
                            <FormGroup>
                                <span>Sucursal</span><br/>
                                <Input size="sm" type="select" name="sucursal" id="sucursal" value={this.state.sucursal} onChange={this.onChangeCarga}  >
                                    <option key={-1} value={0}>Especifique</option>    
                                    {this.state.sucursales.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.I} - {i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup>
                                <span>Semana</span><br/>
                                <Input size="sm" type="select" name="semana" id="semana" value={this.state.semana} onChange={this.onChangeCarga}  >
                                    <option key={-1} value={0}>Especifique</option>    
                                    {this.state.semanas.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.I} - {i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup as={Col}>
                                <br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>
                                <img src="/imagenes/ok.png" alt="cargar" title="cargar"/>
                                </Button>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup as={Col}>
                                <br/>
                                <Button outline color='success' size="sm" onClick={this.nuevoMov}>
                                   <img src="/imagenes/add.png" alt="agregar" title="agregar"/>
                                   &nbsp;Agregar</Button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                                <Button outline color='warning' size="sm" onClick={this.imprimir}>
                                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir"/>
                                    &nbsp;Imprimir
                                </Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.movs} height={500} width={990} editar={this.editar} eliminar={this.eliminar} />
                </div>       
                <Modal id="dlgPlan" name="dlgPlan" isOpen={this.state.mostrarDialogo} toggle={this.handleClose}>
                    <ModalHeader >
                        <span>{this.state.titulo}</span> 
                    </ModalHeader>
                    <ModalBody className="fondo">
                    <div class="row">
                        <div className="col-5">
                            <FormGroup>
                                <span>Fecha</span><br/>
                                <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-6">
                            <FormGroup  >
                                <span>Ruta</span><br/>
                                <Input size="sm" type="select" name="ruta" id="ruta" value={this.state.ruta} onChange={this.onChange}  >
                                    <option key={-1} value={''}>Especifique</option>    
                                    {this.state.rutas.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-9">
                            <FormGroup  >
                                <label>Camion</label>
                                <Input size="sm" type="select" name="camion" id="camion" value={this.state.camion} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.state.camiones.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-9">
                            <FormGroup  >
                                <label>Chofer</label>
                                <Input size="sm" type="select" name="chofer" id="chofer" value={this.state.chofer} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.state.choferes.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <ModalFooter>
                        <Button size="sm" color="success" onClick={this.guardar}>Aceptar</Button>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button size="sm" color="danger" onClick={this.handleClose}>Cancelar</Button>
                    </ModalFooter>
                    </ModalBody>
                </Modal>                       
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    render() {
        let { movs } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:80}}>Fecha</th>
                        <th style={{width:80}}>Ruta</th>
                        <th style={{width:150}}>Camion</th>
                        <th >Chofer</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((esc, i) => (
                        <MovRow key={i} mov={esc} editar={this.props.editar} eliminar={this.props.eliminar} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    editar = (e) =>{
        this.props.editar(this.props.mov);
    }
    eliminar = (e) =>{
        this.props.eliminar(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td style={{width:80}}>{mov.Fecha}</td>
            <td style={{width:80}}>{mov.Ruta}</td>
            <td>{mov.NomCam}</td>
            <td>{mov.NomCh}</td>
            <td style={{width:30, textAlign:'center'}}>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.editar}/>
            </td>
            <td style={{width:30, textAlign:'center'}}>
                <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={this.eliminar}/>
            </td>
        </tr>
        );
    }
}