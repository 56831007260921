import React from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

export default class Menu extends React.Component {
    render() {
        
        let catConta = [{id:'catSuc',nombre:'Sucursales'},{id:'catCam',nombre:'Camiones'},{id:'catChof',nombre:'Choferes'},,{id:'catDest',nombre:'Destinos'},{id:'catProv',nombre:'Proveedores'},{id:'catGas',nombre:'Gasolineras'},{id:'catRutas',nombre:'Rutas'},{id:'catInsumos',nombre:'Insumos'},{id:'catServicios',nombre:'Servicios'}];
        let movConta = [{id:'movPlan',nombre:'Planeacion'},{id:'movOp',nombre:'Operacion'},{id:'movGtos',nombre:'Gastos'}];
        let comConta = [{id:'req',nombre:'Requisiciones'},{id:'oc',nombre:'Ordenes de copras'},{id:'compras',nombre:'Compras'}];
        let repConta = [{id:'rptGastos',nombre:'Relacion de gastos'},{id:'rptViajes',nombre:'Viajes por camion'},];
        let conConta = [{id:'conAuxi',nombre:'Kardex por camion'},];

        let modulos = [
                       {id:"con100", nombre:"Catalogos", opciones:catConta},
                       {id:"con200", nombre:"Operacion", opciones:movConta},
                       {id:"con300", nombre:"Compras", opciones:comConta},
                       {id:"con400", nombre:"Consultas", opciones:conConta},
                       {id:"con500", nombre:"Reportes", opciones:repConta},
                       {id:"con600", nombre:"Procesos", opciones:[]}
                    ];
                        
        return (
            <ul className="nav">
                {modulos.map((e,i) =>{
                    return(
                        <li className="nav-item">
                            <Modulo modulo={e} opcionSeleccionada={this.props.opcionSeleccionada}/>
                        </li>    
                    )
                })}
            </ul>)
    }
}

class Modulo extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  onClick = (e) =>{
      this.toggle();
      //alert(e.target.dataset.opc);
      this.props.opcionSeleccionada(e.target.dataset.opc,e.target.innerText);
  }
  render() {
    const {modulo} = this.props;
    const titulo = modulo.nombre;
    const idMod = modulo.id;
    const opciones = modulo.opciones;
    return (
      <div>
          <a  id={idMod} className="nav-link opcion" href="#">
          {titulo}
        </a>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={idMod} toggle={this.toggle} trigger="legacy" style={{width:400}}>
          <PopoverBody >
              <div className="container" style={{padding:1}}>
                <div className="body" style={{padding:5}}>
                  <div className="row">
                      <div className="col">
                        <ul>
                            {opciones.map((e,i) => <li className="opcionMenu" data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                      </div>
                  </div>
                  </div>
              </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}