import React from 'react';
import { Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Insumos extends React.Component {
    objInicial = {Id:0,Codigo:'',Nombre:'',Depto:1,Linea:1,Sublinea:1,Descripcion:''};
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clave: '',
            nombre:'?',
            articulo:{},
            articulos: [],
            lineas:[],
            unidades:[]
        };
        this.cargarCatalogos();
        this.cargarArticulos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarArticulos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/articulo/articulos?depto=${this.props.depto}&filtro=${this.state.nombre}`;
        const resp = await fetch(url);
        const articulos = await resp.json();
        this.setState({cargando:false, articulos });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/articulo/lineas/${this.props.depto}`;
        let resp = await fetch(url);
        const lineas = await resp.json();
        url = `${this.props.urlws}/articulo/unidades`;
        resp = await fetch(url);
        const unidades = await resp.json();
        this.setState({cargando:false, lineas, unidades });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarArticulos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarArticulos();
    }    
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        if(articulo.Depto > 0 && articulo.Depto !== this.props.depto){
            Swal.fire('Atencion',articulo.Nombre + " no es un " + this.props.nomDepto,'error');
            this.setState({cargando:false});
            return;
        }
        if(articulo.Codigo == ''){
            articulo.Id = 0;
            articulo.Codigo = cve;
            articulo.Depto = this.props.depto;
            articulo.Linea = 1;
            articulo.Sublinea = 1;
         }
         this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            articulo
        });
    }
    guardarArticulo = async(articulo) =>{
        const url = this.props.urlws +"/articulo/wsguardar";
        this.setState({cargando:true});
        const json = JSON.stringify(articulo);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res.substring(0,5) !== "Error"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoArticulo = async() => {
        const { value: codigo } = await Swal.fire({
            title: 'Agregar insumo',
            input: 'text',
            inputLabel: 'Codigo del insumo',
            inputValue: '',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Indique el codigo del insumo'
              }
            }
          });          
          if (codigo) 
              this.cargarArticulo(codigo);
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <NavbarBrand  size="sm" style={{color:'black'}}>Insumos</NavbarBrand >
                    <Collapse isOpen={true} id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" color="success" onClick={this.nuevoArticulo}>+</Button>
                            &nbsp;
                            <Form inline onSubmit={this.onSubmit} >
                            <label size="sm">Nombre</label>&nbsp;
                            <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{width:400}} />
                            &nbsp;
                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                        </Nav>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ArticuloTable articulos={this.state.articulos} height={500} width={990} onRowClick={this.cargarArticulo} />
                </div>
                <ArticuloForm urlws={this.props.urlws} visible={this.state.formVisible} lineas={this.state.lineas} unidades={this.state.unidades} articulo={this.state.articulo} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardarArticulo} depto={this.props.depto}/>
            </Container>
        );
    }
}

class ArticuloTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { articulos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Codigo</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {articulos.map((esc, i) => (
                        <ArticuloRow key={i} articulo={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ArticuloRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.articulo.I);
    }
    render(){
        let {articulo} = this.props;
        return (
        <tr>
            <td>{articulo.I}</td>
            <td>{articulo.N}</td>
            <td>
                <Button color="primary" size="sm" onClick={this.onClick}>
                    <img src="/imagenes/pencil.png" alt="editar" title="editar"/>
                </Button>
            </td>
        </tr>
        );
    }
}

class ArticuloForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //articulo:this.props.articulo,
            articulo:this.props.articulo,
            ...this.props.articulo,
            sublineas:[],
            lin:0
        };
        this.cargarSublineas(this.state.articulo.Linea);
    }
    componentWillReceiveProps(newProps){
        this.setState({
            articulo:newProps.articulo,
            ...newProps.articulo
        });
        this.cargarSublineas(newProps.articulo.Linea);
    }
    cargarSublineas = async (lin) => {
        //alert(this.state.lin + ' === ' + lin);
        if(this.state.lin === lin)
            return;
        this.setState({cargando:true,lin:lin});
        const url = `${this.props.urlws}/articulo/sublineas?depto=${this.props.depto}&linea=${lin}`;
        const resp = await fetch(url);
        const sublineas = await resp.json();
        this.setState({cargando:false, sublineas });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const esc = {
            ...this.state.articulo,
            [campo] : e.target.value
        };
        this.setState({articulo:esc},() =>{
            if(campo === "Linea")
               this.cargarSublineas(esc.Linea);
        });
    }
    guardar = () =>{
        if(this.state.articulo.Nombre === ''){
            Swal.fire('Atencion','Especifique el nombre del insumo','error');
            return;
        }

        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.articulo);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.articulo === undefined)
            return null;
        if(Object.keys(this.props.articulo).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div class="row">
                        <div className="col-4" >
                            <label>Codigo</label>
                            <Input size="sm" type="text" name="Codigo" value={this.state.articulo.Codigo} />
                        </div>
                    </div>
                    <div class="row">
                        <div className="col" >
                            <label>Nombre</label>
                            <Input size="sm" type="text" name="Nombre" value={this.state.articulo.Nombre} onChange={this.onChange} style={{ width: 700 }} />
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-4">
                            <FormGroup  >
                                <label>Linea</label>
                                <Input size="sm" type="select" name="Linea" id="Linea" value={this.state.articulo.Linea} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.lineas.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-5">
                            <FormGroup  >
                                <label>SubLinea</label>
                                <Input size="sm" type="select" name="Sublinea" id="Sublinea" value={this.state.articulo.Sublinea} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.state.sublineas.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup  >
                                <label>Unidad</label>
                                <Input size="sm" type="select" name="Unidad" id="Unidad" value={this.state.articulo.Unidad} onChange={this.onChange}  >
                                    <option key={-1} value="">Especifique</option>    
                                    {this.props.unidades.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col">
                            <FormGroup  >
                                <label>Descripcion</label>
                                <textarea class="form-control" rows="5" name="Descripcion" value={this.state.articulo.Descripcion} onChange={this.onChange} ></textarea>
                            </FormGroup>
                        </div>                        
                    </div>
                </Form>
                <br />
                <Button size="sm" color="primary" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button size="sm" color="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}