import React from 'react';
import { Nav,Navbar,InputGroup, InputGroupAddon, Collapse, Table, Form, FormGroup, Modal, ModalBody, Input, Button, Container, Spinner, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Rutas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clave: '',
            nombre:'?',
            objeto:{},
            objetos: [],
            tipos: [],
            ciudades:[],
            gasolineras:[],
            tiposGas:[{I:'I', N:'Ida'},{I:'V', N:'Vuelta'}]
        };
        this.cargarCatalogos();
        this.cargarObjetos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarObjetos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/viaje/rutas?filtro=${this.state.nombre}`;
        const resp = await fetch(url);
        const objetos = await resp.json();
        this.setState({cargando:false, objetos });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/viaje/ciudades?filtro=`;
        let resp = await fetch(url);
        const ciudades = await resp.json();
        url = `${this.props.urlws}/viaje/tiposRutas`;
        resp = await fetch(url);
        const tipos = await resp.json();
        url = `${this.props.urlws}/prov/gasolineras`;
        resp = await fetch(url);
        const gasolineras = await resp.json();
        this.setState({cargando:false, ciudades, tipos, gasolineras });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarObjetos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarObjetos();
    }    
    cargar = async (cve) => {
        this.setState({cargando:true});
        let gasRuta = [];
        let url = this.props.urlws + "/viaje/ruta/" + cve;
        let resp = await fetch(url);
        const objeto = await resp.json();
        if(objeto.Id == ""){
            objeto.Id = cve;
        }else{
           url = this.props.urlws + "/viaje/gasRutas/" +cve;
           resp = await fetch(url);
           gasRuta = await resp.json();
        }
        objeto.gasRuta = gasRuta;
        this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            objeto
        });
    }
    guardar = async(dato) =>{
        const url = this.props.urlws +"/viaje/wsguardarRuta";
        this.setState({cargando:true});
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevo = () => {
        Swal.fire({
            title: 'Clave',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: (cod) => {
                this.cargar(cod);
            },
            allowOutsideClick: () => !Swal.isLoading()
          });
    }
    imprimir = () =>{
        const url = `${this.props.urlws}/viaje/ciudadesPdf`;
        window.open(url, '', '');
    }
    excel = () =>{
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <Collapse isOpen={true} id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" color="success" outline onClick={this.nuevo}><img src="/imagenes/add.png"/></Button>
                            &nbsp;
                            <Form inline onSubmit={this.onSubmit} >
                            <label size="sm">Nombre</label>&nbsp;
                            <InputGroup>
                                <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{width:400}} />
                                <InputGroupAddon addonType="append">
                                    <Button size="sm" color="success" onClick={this.onBntBuscarClick}> Buscar <img src="/imagenes/zoom.png" /> </Button>
                                </InputGroupAddon>
                            </InputGroup>                            
                            &nbsp;
                            &nbsp;
                            <Button size="sm" color="success" outline onClick={this.imprimir}><img src="/imagenes/printer.png"/></Button>
                            &nbsp;
                            <Button size="sm" color="success" outline onClick={this.excel}><img src="/imagenes/excel.png"/></Button>
                        </Form>
                        </Nav>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ObjetoTable objetos={this.state.objetos} height={500} width={990} onRowClick={this.cargar} />
                </div>
                <ObjetoForm urlws={this.props.urlws} visible={this.state.formVisible} tipos={this.state.tipos} ciudades={this.state.ciudades} gasolineras={this.state.gasolineras} tiposGas={this.state.tiposGas} objeto={this.state.objeto} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardar} />
            </Container>
        );
    }
}

class ObjetoTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { objetos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50}}>Id</th>
                        <th>Nombre</th>
                        <th style={{width:30}}></th>
                    </tr>
                </thead>
                <tbody>
                    {objetos.map((o, i) => (
                        <ObjetoRow key={i} objeto={o} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ObjetoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.objeto.I);
    }
    render(){
        let {objeto} = this.props;
        return (
        <tr>
            <td>{objeto.I}</td>
            <td>{objeto.N}</td>
            <td>
                <img src="/imagenes/edit.png" onClick={this.onClick} alt="editar" title="editar"/>
            </td>
        </tr>
        );
    }
}

class ObjetoForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            id:0,
            mostrarModal:false,
            ida:'I',
            litros:0,
            gasolinera:0,
            objeto:this.props.objeto,
            gasRuta:this.props.objeto.gasRuta
        };
    }
    componentWillReceiveProps(newProps){
        this.setState({
            ida:'I',
            litros:0,
            gasolinera:0,
            mostrarModal:false,
            objeto:newProps.objeto,
            gasRuta:newProps.objeto.gasRuta
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const obj = {
            ...this.state.objeto,
            [campo] : e.target.value
        };
        this.setState({objeto:obj});
    }
    onChangeGas = (e) => {
        const campo = e.target.name;
        this.setState({[campo]:e.target.value});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.objeto);
            }
          })        
    }
    nuevaGas = () =>{
        this.setState({mostrarModal:true, id:0, litros:0, gasolinera:0});
    }
    handleClose= () =>{
        this.setState({mostrarModal:false});
    }
    agregarGas = async() =>{
        const dato = {
            Id:this.state.id,
            R:this.state.objeto.Id,
            G:this.state.gasolinera,
            L:this.state.litros,
            I:this.state.ida
        };
        const url = this.props.urlws +"/viaje/wsguardarGasRuta";
        this.setState({cargando:true});
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        if(res === "ok"){
            this.cargarGasRuta();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    quitarGas = async(id) =>{
        const url = this.props.urlws +"/viaje/borrarGasRuta/" + id;
        const resp = await fetch(url,{
            method: 'POST'
          });
        const res = await resp.text();
        if(res === "ok"){
            this.cargarGasRuta();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    cargarGasRuta = async () => {
        const url = this.props.urlws + "/viaje/gasRutas/" + this.state.objeto.Id;
        const resp = await fetch(url);
        const gasRuta = await resp.json();
        this.setState({gasRuta,mostrarModal:false});
    }
    editar = (g) =>{
        console.log(g);
        this.setState({mostrarModal:true, id:g.Id, litros:g.L, gasolinera:g.G, ida:g.I.substring(0,1)});
    }
    quitar = (g) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea quitar " + g.N + " de " + g.I + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.quitarGas(g.Id);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.objeto === undefined)
            return null;
        if(Object.keys(this.props.objeto).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div class="row">
                        <div className="col-2">
                            <FormGroup md="2" >
                                <label>Id</label>
                                <Input size="sm" type="text" name="Clave" value={this.state.objeto.Id} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <label>Tipo</label>
                                <Input size="sm" type="select" name="Tipo" id="Tipo" value={this.state.objeto.Tipo} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.props.tipos.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup>
                                <label>Nombre</label>
                                <Input size="sm" type="text" name="Nombre" value={this.state.objeto.Nombre} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-3">
                            <FormGroup>
                                <label>Origen</label>
                                <Input size="sm" type="select" name="Origen" id="Origen" value={this.state.objeto.Origen} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.props.ciudades.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup>
                                <label>Destino</label>
                                <Input size="sm" type="select" name="Destino" id="Destino" value={this.state.objeto.Destino} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.props.ciudades.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-1">
                            <FormGroup>
                                <label>Kms. Ida</label>
                                <Input size="sm" type="text" name="KmsIda" value={this.state.objeto.KmsIda} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-1">
                            <FormGroup>
                                <label>Kms. Vuelta</label>
                                <Input size="sm" type="text" name="KmsVuelta" value={this.state.objeto.KmsVuelta} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-2">
                            <FormGroup>
                                <label>Casetas CUU AUTOBUS</label>
                                <Input size="sm" type="text" name="CasetasA1" value={this.state.objeto.CasetasA1} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <label>Casetas Efectivo AUTOBUS</label>
                                <Input size="sm" type="text" name="CasetasE1" value={this.state.objeto.CasetasE1} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <label>Casetas CUU SPRINTER</label>
                                <Input size="sm" type="text" name="CasetasA2" value={this.state.objeto.CasetasA2} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <label>Casetas Efectivo SPRINTER</label>
                                <Input size="sm" type="text" name="CasetasE2" value={this.state.objeto.CasetasE2} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                </Form>
                <br />
                <div className="row">
                    <div className="col-3">
                        <Button size="sm" color="success" onClick={this.guardar}>Aceptar</Button>
                        {'  '}
                        <Button size="sm" color="danger" onClick={this.props.onClose}>Cancelar</Button>
                    </div>
                    <div className="col">
                        <span>Gasolineras</span>
                        {'  '}
                        <Button size="sm" color="success" outline onClick={this.nuevaGas}><img src="/imagenes/add.png" /></Button>
                        <div style={{height:5}}></div>
                        <div style={{ height: this.props.height, width: this.props.width, overflow: 'auto' }}>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th style={{ width: 300 }}>Ciudad</th>
                                        <th className="celdaNumero" style={{ width: 50 }}>Litros</th>
                                        <th style={{ width: 50 }}> &nbsp;</th>
                                        <th style={{ width: 30 }}></th>
                                        <th style={{ width: 30 }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.gasRuta.map((i, k) => (
                                        <tr key={i.Id} >
                                            <td>{i.N}</td>
                                            <td>{i.C}</td>
                                            <td className="celdaNumero">{i.L}</td>
                                            <td>{i.I}</td>
                                            <td>
                                                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={()=>{ this.editar(i);}}/>
                                            </td>
                                            <td>
                                                <img src="/imagenes/trash.png" alt="quitar" title="quitar" onClick={()=>{ this.quitar(i);}}/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <Modal isOpen={this.state.mostrarModal} toggle={this.handleClose} size="sm">
                            <ModalHeader>
                               <h6>Agregar gasolinera</h6> 
                            </ModalHeader>  
                            <ModalBody>
                                <div className="row">
                                    <div className="col">
                                        <FormGroup>
                                            <Input size="sm" type="select" name="gasolinera" id="gasolinera" value={this.state.gasolinera} onChange={this.onChangeGas}  >
                                                <option key={-1} value={-1}>Especifique</option>
                                                {this.props.gasolineras.map((i, k) => (
                                                    <option key={i.I} value={i.I}>{i.N} // {i.C}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <FormGroup>
                                            <   label>Ida o vuelta </label>
                                            <Input size="sm" type="select" name="ida" id="ida" value={this.state.ida} onChange={this.onChangeGas}  >
                                                <option key={-1} value={-1}>Especifique</option>
                                                {this.props.tiposGas.map((i, k) => (
                                                    <option key={i.I} value={i.I}>{i.N}</option>
                                                ))}
                                            </Input>
                                            <InputGroupAddon addonType="append">
                                            </InputGroupAddon>
                                        </FormGroup>
                                    </div>
                                    <div className="col">
                                        <FormGroup>
                                            <label>Litros</label>
                                            <Input size="sm" type="text" name="litros" value={this.state.litros} onChange={this.onChangeGas} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <Button size="sm" color="success" onClick={this.agregarGas}>Aceptar</Button>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </Container>
        );
    }
}